import React from "react"
import MarkdownIt from "markdown-it"
import Layout from "../layouts"
import { graphql } from "gatsby"

import Breadcrumb from "~components/Breadcrumb"
import Box from "~components/Box"
import Container from "~components/Container"
import Hero from "~components/Hero"
import SEO from "~components/SEO"

import { ReactComponent as CalendarIcon } from "~images/svg/calendar-alt-regular.svg"
import { ReactComponent as MarkerIcon } from "~images/svg/map-marker-alt-solid.svg"
import { ReactComponent as ClockIcon } from "~images/svg/clock-regular.svg"
import { ReactComponent as ReceiptIcon } from "~images/svg/receipt-solid.svg"
// import UsersIcon from "~images/svg/users-solid.svg"

import { getImage, withArtDirection } from "gatsby-plugin-image"
import { toHoursAndMinutes } from "~utils/datetime"

const md = MarkdownIt()

const Visit = ({ pageContext, data }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const { bannerImageMobile, bannerImageDesktop, visit } = data

  const images = withArtDirection(getImage(bannerImageMobile), [
    {
      media: "(min-width: 768px)",
      image: getImage(bannerImageDesktop),
    },
  ])

  const { hours, minutes } = toHoursAndMinutes(visit.duration)

  return (
    <Layout>
      <SEO title={visit.title} description={visit.summary} />

      <Hero background={images} />

      <Container>
        <section className="tw-mt-8">
          <h1
            className="h1 tw-text-center"
            dangerouslySetInnerHTML={{ __html: visit.title }}
          />

          <hr className="tw-mt-8 tw-text-center" />

          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={visit.title}
          />

          <Box className="tw-px-8">
            <h2 className="h2 tw-border-b tw-border-current tw-pb-2.5">
              Programme
            </h2>

            <div
              className="tw-mt-4 tw-mb-8 tw-space-y-4"
              dangerouslySetInnerHTML={{
                __html: md.render(visit.description),
              }}
            />

            <h2 className="h2 tw-border-b tw-border-current tw-text-secondary tw-pb-2.5">
              Modalités
            </h2>

            <ul className="tw-flex tw-flex-col tw-space-y-2.5 tw-mt-5">
              {
                <li className="tw-flex">
                  <CalendarIcon
                    width="30"
                    className="tw-flex tw-justify-content-center tw-flex-none tw-relative tw--top-1 tw-mr-2"
                  />
                  <div className="tw-flex tw-flex-col">
                    {visit.visit_dates && visit.visit_dates.length > 0 ? (
                      visit.visit_dates
                        .map(({ date }) => date)
                        .sort((a, b) => b - a)
                        .map((date, dateIndex) => {
                          return (
                            <span key={dateIndex}>
                              {new Intl.DateTimeFormat("fr", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                              }).format(new Date(date))}
                            </span>
                          )
                        })
                    ) : (
                      <span>
                        Pas de date prévue pour cette visite pour le moment.
                      </span>
                    )}
                  </div>
                </li>
              }
              {visit.location && (
                <li className="tw-flex">
                  <MarkerIcon
                    width="30"
                    className="tw-flex tw-justify-content-center tw-flex-none tw-relative tw--top-1 tw-mr-2"
                  />
                  <span dangerouslySetInnerHTML={{ __html: visit.location }} />
                </li>
              )}
              {!!visit.duration && (
                <li className="tw-flex">
                  <ClockIcon
                    width="30"
                    className="tw-flex tw-justify-content-center tw-flex-none tw-relative tw--top-1 tw-mr-2"
                  />
                  Durée&nbsp;:&nbsp;
                  {hours > 0 && `${hours}h`}
                  {minutes !== 0 && `${minutes.toString().padStart(2, "0")}`}
                </li>
              )}
              {!!visit.price && (
                <li className="tw-flex">
                  <ReceiptIcon
                    width="30"
                    className="tw-flex tw-justify-content-center tw-flex-none tw-relative tw--top-1 tw-mr-2"
                  />
                  Tarif&nbsp;:&nbsp;
                  <span
                    dangerouslySetInnerHTML={{
                      __html: new Intl.NumberFormat("fr", {
                        style: "currency",
                        currency: "EUR",
                      }).format(visit.price),
                    }}
                  />
                </li>
              )}
              {/* {
                <li>
                  <UsersIcon width="30" className="tw-inline tw-relative tw--top-1 tw-mr-2 " />
                  {visit.public_type}
                </li>
              } */}
            </ul>
          </Box>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($visitId: String) {
    visit: strapiVisit(id: { eq: $visitId }) {
      description
      duration
      id
      location
      price
      public_type
      strapiId
      summary
      title
      visit_dates {
        date
      }
    }
    bannerImageMobile: file(
      relativePath: { eq: "banners/visit-page-banner-mobile.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    bannerImageDesktop: file(
      relativePath: { eq: "banners/visit-page-banner.webp" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
  }
`

export default Visit
